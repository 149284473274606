@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}



#main {
  height: calc(100vh - 48px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tracking-in-expand-fwd {
  -webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    0.5s both;
          animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    0.5s both;
}

@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

.text-focus-in-1 {
  -webkit-animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.1s both;
          animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.1s both;
}
.text-focus-in-2 {
  -webkit-animation: text-focus-in 0.27s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.5s both;
          animation: text-focus-in 0.27s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.5s both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(6px);
            filter: blur(6px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(6px);
            filter: blur(6px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

.title {
  font-size: 3rem;
  color: white;
}

.sub-text {
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
  letter-spacing: 1px;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.8);
}

.main {
  text-align: center;
  justify-content: center;
}

.social {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social a {
  font-size: 2rem;
  padding: 0 0.5rem;
  color: rgba(255, 255, 255, 0.8);
}

.linked-in:hover {
  color: white;
  transition: 0.2s ease;
}
.github:hover {
  color: white;
  transition: 0.2s ease;
}

@media only screen and (min-width: 922px) {
    .title {
      font-size: 6rem;
    }

    .sub-text {
      font-size: 2rem;
    }
    .social a{
      font-size: 2.5rem;
    }
}
* {
  font-family: Manrope, sans-serif;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


* {
  font-family: Manrope, sans-serif;
}
